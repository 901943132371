import { Heading, LiveStreamSimulator, TimeUtil } from 'cuenect-web-core'
import i18n from 'i18next'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ContentContainer,
  InnerContainer,
  LineContainer,
  LiveStreamContainer,
} from '../components'
import { PageFrame, PageParams } from '../components/organisms/pageFrame'
import { ProgramContainer } from '../components/organisms/programContainer'
import { eventConfig } from '../config'

const LivePage = (params: PageParams) => {
  const {
    pageContext: { lang, program },
  } = params

  const { t } = useTranslation('live')
  const [loaded, setLoaded] = React.useState(false)

  useEffect(() => {
    i18n.changeLanguage(lang)
    setLoaded(true)
  }, [])

  return (
    <PageFrame {...params} pageName="auditorium">
      <ContentContainer>
        <LiveStreamContainer
          source={eventConfig.sources[lang]}
          slug={`auditorium-${lang}`}
        />
      </ContentContainer>
    </PageFrame>
  )
}

export default LivePage
